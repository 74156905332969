.pending-events {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.header-title {
  font-size: 18px;
  color: #d89614;
  text-wrap: wrap;
  padding-block: 0.5rem;
}

.header-title-success {
  font-size: 18px;
  color: #52c41a;
  text-wrap: wrap;
  padding-block: 0.5rem;
}

.header-title-warning {
  font-size: 18px;
  color: #faad14;
  text-wrap: wrap;
  padding-block: 0.5rem;
}

.title {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.item {
  display: grid;
  grid-template-columns: minmax(100px, auto) minmax(40px, auto) minmax(
      40px,
      auto
    ) minmax(200px, auto) 1fr;
  grid-gap: 32px;
}

.box {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.label {
  color: var(--neutral-color-7);
  font-size: 11px;
}

.value {
  font-weight: 600;
}

.loadMore {
  text-align: center;
  padding: 16px;
}

.button-success {
  background: #52c41a !important;
}

.button-success-ghost {
  border-color: #52c41a !important;
  color: #52c41a !important;
}

.button-warning {
  border-color: #faad14 !important;
  color: #faad14 !important;
}

@media (max-width: 1000px) {
  .item {
    display: block;
  }
}

@media (max-width: 1700px) {
  .item {
    grid-template-columns:
      minmax(40px, auto) minmax(40px, auto) minmax(100px, auto) minmax(
        100px,
        auto
      )
      1fr;
  }
}
