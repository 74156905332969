.button-save {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.cards {
  display: flex;
  flex-direction: column;

  gap: 1rem;
}

.card-border {
  border-radius: var(--border-radius);
  border-color: var(--border-color);
  box-shadow: var(--box-shadow);
  color: var(--title-color-dark);
}
