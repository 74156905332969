.form {
}

.button-save {
  margin-top: 1rem;
}

.cards {
  display: flex;
  flex-direction: column;

  gap: 2rem;
}

.card-border {
  border-radius: var(--border-radius);
  border-color: var(--border-color);
  box-shadow: var(--box-shadow);
}
